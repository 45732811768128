<template>
  <b-card header="Pengelolaan Nilai" body-class="pt-2" class="shadow-sm">
    <SemesterMatakuliah
      @onChange="filterBySemester"
      :loading="loading"
      :semester-list="semesterList"
      :selected-semester="semesterId"
    >
      <title-icon-button
        tooltipText="Mutakhir Data"
        @onClick="refreshData"
      >
        <template v-slot:icon>
          <refresh-icon></refresh-icon>
        </template>
      </title-icon-button>
    </SemesterMatakuliah>

    <c-table
      :table-data="items"
      :fields="captions"
      primary-key="klsId"
      :loading="loadingItems"
    >
      <template v-slot:cell(namaDosen)="data">
        {{ concatLectureName(data.item) }}
      </template>

      <template v-slot:cell(action)="data">
        <b-button-group size="sm">
          <c-button
            variant="outline-info"
            tooltip-text="Lihat Komponen Nilai"
            @click="redirectToPageWith(data.item, 'dosen.komponen-nilai')"
          >
            <component-icon></component-icon>
          </c-button>
          <c-button
            variant="outline-info"
            tooltip-text="Lihat Nilai Mahasiswa"
            @click="redirectToPageWith(data.item, 'dosen.mahasiswa-nilai')"
          >
            <assessment-icon></assessment-icon>
          </c-button>
        </b-button-group>
      </template>
    </c-table>
  </b-card>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import { isNil, lectureNameConcat, delay, activeSemesterObject } from '@/helpers'
import {
  TableMixin, ModalMessageMixin,
  AssessmentIcon, RefreshIcon, ComponentIcon,
  Table as CTable,
  TitleIconButton, Button as CButton
} from '@/components'
// module
import SemesterMatakuliah from '@/modules/akademik/Semester'

export default {
  mixins: [TableMixin, ModalMessageMixin],
  name: 'KelolaNilai',
  components: {
    TitleIconButton,
    CTable,
    SemesterMatakuliah,
    CButton,
    AssessmentIcon,
    RefreshIcon,
    ComponentIcon
  },
  data () {
    return {
      fields: [
        { key: 'kodeMatkul', label: 'Kode Matkul', sortable: true },
        { key: 'klsMakulNama', label: 'Nama Matkul', sortable: true },
        { key: 'klsNama', label: 'Nama Kelas', sortable: true },
        { key: 'namaFakultas', label: 'Fakultas', sortable: true },
        { key: 'klsMakulSks', label: 'SKS', sortable: true },
        { key: 'namaDosen', label: 'Dosen', sortable: false },
        { key: 'action', label: 'Aksi', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activeSemester: 'faculty/getSemesterActive'
    }),
    ...mapState('komponenPenilaian', {
      semesterId: 'semesterId',
      semesterList: 'semesterList',
      loading: 'loadingSemester'
    })
  },
  beforeMount () {
    this.getAllSemester().then(() => {
      if (isNil(this.semesterId)) {
        this.filterBySemester(this.activeSemester)
      } else {
        this.filterBySemester(this.semesterId)
      }
    })
  },
  methods: {
    ...mapMutations({
      setSelectedSemester: 'komponenPenilaian/setSelectedSemesterId',
      setSelectedSemesterName: 'komponenPenilaian/setSelectedSemesterName',
      setActiveSemester: 'faculty/setActiveSemester'
    }),
    ...mapActions({
      setFacultyCode: 'faculty/facultyCode',
      getAllSemester: 'komponenPenilaian/getAllSemester',
      getLecturerClass: 'krs/getLecturerClass'
    }),
    dataProvider () {
      return this.getLecturerClass()
        .catch(() => this.modalMessage('Pilih semester terlebih dahulu.', {
          noRedirect: true
        }))
    },
    filterBySemester (semesterId) {
      this.setSelectedSemester(semesterId)

      const semester = activeSemesterObject(this.semesterList, semesterId)

      if (!isNil(semester)) {
        this.setSelectedSemesterName(semester.smtNama)
      }

      this.tableDataProvider()
    },
    refreshData () {
      if (isNil(this.semesterId)) {
        this.modalMessage('Pilih semester terlebih dahulu.', {
          noRedirect: true
        })
      } else {
        this.filterBySemester(this.semesterId)
      }
    },
    concatLectureName ({ gelarBelakangDosen, gelarDepanDosen, namaDosen }) {
      return lectureNameConcat(namaDosen, gelarBelakangDosen, gelarDepanDosen)
    },
    redirectToPageWith (data, routeTo) {
      delay(60).then(() => {
        this.setFacultyCode(data.kodeFakultas)
      }).then(() => {
        this.$router.push({
          name: routeTo,
          params: { kelasId: data.klsId }
        })
      })
    }
  }
}
</script>

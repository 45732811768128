<template>
  <b-alert
    :show="getCompletion"
    variant="warning"
  >
    <h3 class="alert-heading">
      Kesalahan...
    </h3>
    <hr class="my-0">
    <p
      class="mb-4"
      v-if="notExists"
    >
      {{ completionText }}
    </p>
    <p
      class="mb-4"
      v-if="notExists === false && unreached"
    >
      {{ percentageReachText }}
    </p>

    <b-button
      variant="outline-danger"
      :to="{ name: 'dosen.komponen-nilai', params: $route.params }"
    >
      Lengkapi
    </b-button>
  </b-alert>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AssessmentComponentCompletion',
  data () {
    return {
      completionText: 'Komponen Penilaian belum terisi!',
      percentageReachText: 'Jumlah Persentase Komponen Penilaian kurang dari 100%'
    }
  },
  computed: {
    ...mapState('komponenPenilaian', {
      reached: 'percentageReached',
      exists: 'assessmentComponentExist'
    }),
    ...mapGetters('komponenPenilaian', {
      getCompletion: 'getAssessmentComponentCompletion'
    }),
    unreached () {
      return this.reached === false
    },
    notExists () {
      return this.exists === false
    }
  }
}
</script>

<template>
  <b-form @submit.stop.prevent="onSubmit">
    <b-form-group
      label="Nama Komponen Penilaian"
      :invalid-feedback="errors.komPenNama"
    >
      <b-form-select
        text-field="nama_komponen"
        value-field="nama_komponen"
        v-model="model.komPenNama"
        :options="listKomponenPenilaian"
        :state="errorKomPenState"
        :disabled="formState"
        required
      >
        <template slot="first">
          <option
            :value="null"
            disabled
          >
            -- Pilih Komponen Penilaian --
          </option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Persentase Komponen Penilaian"
      :invalid-feedback="errors.komPenPersen"
    >
      <b-form-input
        v-model="model.komPenPersen"
        min="0"
        max="100"
        type="number"
        step="0.01"
        :state="errorKomPenState"
        required
      />
    </b-form-group>

    <b-button
      type="submit"
      variant="primary"
    >
      Simpan
    </b-button>
  </b-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { formError } from '@/helpers'

export default {
  name: 'KomponenPenilaianForm',
  data () {
    return {
      model: {
        komPenNama: null,
        komPenPersen: null
      },
      errors: {
        komPenNama: null,
        komPenPersen: null
      }
    }
  },
  computed: {
    ...mapGetters('komponenPenilaian', {
      listKomponenPenilaian: 'getListKomponenPenilaian',
      komponenPenilaian: 'getKomponenPenilaian'
    }),
    ...mapState('komponenPenilaian', {
      formState: 'editState'
    }),
    errorKomPenState () {
      if (this.model.komPenNama !== null &&
        this.model.komPenPersen !== null) {
        return this.errors.komPenNama === null &&
          this.errors.komPenPersen === null
      }

      return null
    }
  },
  beforeMount () {
    this.model.komPenId = this.komponenPenilaian.komPenId
    this.model.komPenKlsId = this.komponenPenilaian.komPenKlsId
    this.model.komPenNama = this.komponenPenilaian.komPenNama
    this.model.komPenPersen = this.komponenPenilaian.komPenPersen
  },
  methods: {
    ...mapActions('komponenPenilaian', [
      'storeKomponenNilai',
      'updateKomponenNilai'
    ]),
    closeModalAfterAction () {
      this.$emit('afterSave')
    },
    onSubmit () {
      if (this.model.komPenNama && this.model.komPenPersen) {
        let action

        if (this.formState === true) {
          action = this.updateKomponenNilai(this.model)
        } else {
          this.model.komPenKlsId = parseInt(this.$route.params.kelasId)
          action = this.storeKomponenNilai(this.model)
        }

        action.then(() => {
          this.closeModalAfterAction()
        }).catch((err) => {
          this.$nextTick(() => {
            this.errors = formError(err.data)
          })
        })
      }
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6">
      <loading-card
        :loading="loading"
        title="Informasi Matakuliah"
      >
        <dl class="row">
          <dt class="col-lg-4">
            Kode Matakuliah
          </dt>
          <dd class="col-lg-8">
            {{ kodeMatkul }}
          </dd>
          <dt class="col-lg-4">
            Nama Matakuliah
          </dt>
          <dd class="col-lg-8">
            {{ klsMakulNama }}
          </dd>
          <dt class="col-lg-4">
            SKS
          </dt>
          <dd class="col-lg-8">
            {{ klsMakulSks }}
          </dd>
        </dl>
      </loading-card>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6">
      <loading-card
        :loading="loading"
        title="Informasi Dosen Kelas"
      >
        <dl class="row">
          <dt class="col-lg-4">
            Nama Dosen
          </dt>
          <dd class="col-lg-8">
            <p
              class="my-0"
              v-for="(dosen, index) in mataKuliah.dosen"
              :key="index"
            >
              {{ getDosenKe(dosen, index) }}. {{ getNamaDosen(dosen) }}
            </p>
          </dd>
          <dt class="col-lg-4">
            Nama Kelas
          </dt>
          <dd class="col-lg-8">
            {{ klsNama }}
          </dd>
        </dl>
      </loading-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import LoadingCard from '@/components/LoadingCard'
import { lectureNameConcat } from '@/helpers'

export default {
  name: 'DetailMatakuliah',
  data () {
    return {
      defaultValue: 'n/a'
    }
  },
  components: {
    LoadingCard
  },
  computed: {
    ...mapGetters('users', {
      userProfile: 'getUserProfile'
    }),
    ...mapState('komponenPenilaian', {
      loading: 'loadingMatakuliah',
      mataKuliah: 'matakuliah'
    }),
    kodeMatkul () {
      return this.mataKuliah
        ? this.mataKuliah.mkkurKode
        : this.defaultValue
    },
    klsMakulNama () {
      return this.mataKuliah
        ? this.mataKuliah.klsMakulNama
        : this.defaultValue
    },
    klsMakulSks () {
      return this.mataKuliah
        ? this.mataKuliah.klsMakulSks
        : this.defaultValue
    },
    klsNama () {
      return this.mataKuliah
        ? this.mataKuliah.klsNama
        : this.defaultValue
    }
  },
  methods: {
    getNamaDosen (dosen) {
      return dosen ? lectureNameConcat(
        dosen.pegNama,
        dosen.pegGelarBelakang,
        dosen.pegGelarDepan
      ) : this.defaultValue
    },
    getDosenKe (dosen, idx) {
      const index = idx + 1
      return dosen.dsnkDosenKe ? dosen.dsnkDosenKe : index
    }
  }
}
</script>

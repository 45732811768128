var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"bimbingan-akademik"}},[_c('b-card',{staticClass:"shadow-sm",attrs:{"header":"Bimbingan Akademik"}},[_c('c-table',{attrs:{"table-data":_vm.items,"fields":_vm.captions,"primary-key":"klsId","loading":_vm.loadingItems},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"cell(ipIp)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ip")(item.ipIp))+" ")]}},{key:"cell(krsJamMulai)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("tanggal")(item.krsJamMulai))+" ")]}},{key:"cell(sksDiambil)",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("sks")(item.sksDiambil))+" ")])]}},{key:"cell(sksDisetujui)",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("sks")(item.sksDisetujui))+" ")])]}},{key:"cell(sksTdksetujui)",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("sks")(item.sksTdksetujui))+" ")])]}},{key:"cell(sksBatal)",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("sks")(item.sksBatal))+" ")])]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('TitleIconButton',{attrs:{"custom-icon":true,"tooltipText":"Detail Akademik Mahasiswa"},on:{"onClick":function($event){return _vm.$router.push({ name: _vm.toDetailGuidance, params: { mhsNiu: item.mhsNiu } })}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('info-icon')]},proxy:true}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
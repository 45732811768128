<template>
  <div id="bimbingan-akademik">
    <b-card header="Bimbingan Akademik" class="shadow-sm">
      <c-table
        :table-data="items"
        :fields="captions"
        primary-key="klsId"
        :loading="loadingItems"
      >
        <template v-slot:cell(index)="{ index }">
          {{ index+1 }}
        </template>
        <template v-slot:cell(ipIp)="{ item }">
          {{ item.ipIp | ip }}
        </template>
        <template v-slot:cell(krsJamMulai)="{ item }">
          {{ item.krsJamMulai | tanggal }}
        </template>
        <template v-slot:cell(sksDiambil)="{ item }">
          <p class="text-center">
            {{ item.sksDiambil | sks }}
          </p>
        </template>
        <template v-slot:cell(sksDisetujui)="{ item }">
          <p class="text-center">
            {{ item.sksDisetujui | sks }}
          </p>
        </template>
        <template v-slot:cell(sksTdksetujui)="{ item }">
          <p class="text-center">
            {{ item.sksTdksetujui | sks }}
          </p>
        </template>
        <template v-slot:cell(sksBatal)="{ item }">
          <p class="text-center">
            {{ item.sksBatal | sks }}
          </p>
        </template>
        <template v-slot:cell(action)="{ item }">
          <TitleIconButton
            :custom-icon="true"
            tooltipText="Detail Akademik Mahasiswa"
            @onClick="$router.push({ name: toDetailGuidance, params: { mhsNiu: item.mhsNiu } })"
          >
            <template v-slot:icon>
              <info-icon />
            </template>
          </TitleIconButton>
        </template>
      </c-table>
    </b-card>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { parseDate, parseIfNotNull, parseIfNotZero } from '@/helpers'
import {
  TableMixin,
  Table as CTable,
  TitleIconButton, InfoIcon
} from '@/components'
// module

export default {
  mixins: [TableMixin],
  filters: {
    tanggal: (val) => parseDate(val),
    sks: (val) => parseIfNotNull(val),
    ip: (val) => parseIfNotZero(val)
  },
  name: 'KelolaBimbinganAkademik',
  components: {
    TitleIconButton,
    InfoIcon,
    CTable
  },
  data () {
    return {
      loading: false,
      perPage: 10,
      fields: [
        { key: 'index', label: '#', sortable: true },
        { key: 'mhsNiu', label: 'No. BP', sortable: true },
        { key: 'mhsNama', label: 'Mahasiswa', sortable: false },
        { key: 'prodiNamaResmi', label: 'Program Studi', sortable: false },
        { key: 'ipIp', label: 'IP Semester', sortable: false },
        { key: 'ipIpk', label: 'IPK', sortable: false },
        { key: 'krsJamMulai', label: 'Tanggal KRS', sortable: true },
        { key: 'sksDiambil', label: 'SKS Diambil', sortable: false },
        { key: 'sksDisetujui', label: 'SKS Disetujui', sortable: false },
        { key: 'sksTdksetujui', label: 'SKS Tidak Disetujui', sortable: false },
        { key: 'sksBatal', label: 'SKS Batal', sortable: false },
        { key: 'action', label: 'Aksi', sortable: false }
      ],
      redirectTo: {
        name: 'dosen.bimbingan-akademik'
      },
      toDetailGuidance: 'dosen.detail-akademik-mahasiswa'
    }
  },
  beforeMount () {
    this.loading = false
    this.tableDataProvider()
    this.setMahasiswa({})
    this.setFacultyCode(this.userProfile.pegFakKode)
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === this.toDetailGuidance) {
      this.mahasiswaProfile(to.params.mhsNiu).then(() => next())
    } else {
      next()
    }
  },
  computed: mapGetters({
    userProfile: 'users/getUserProfile'
  }),
  methods: {
    ...mapMutations({
      setMahasiswa: 'krs/setMahasiswa',
      setFacultyCode: 'faculty/setFacultyCode'
    }),
    ...mapActions({
      mahasiswaProfile: 'krs/getStudentProfile',
      dataProvider: 'krs/getGuidanceList'
    })
  }
}
</script>

<template>
  <div>
    <b-skeleton-wrapper :loading="loadingGrantAccess">
      <template #loading>
        <b-alert class="shadow-sm" :show="true" variant="light">
          <b-skeleton class="alert-heading h3" height="10%" width="30%"></b-skeleton>
          <hr class="mt-0 mb-1">
          <p class="mb-3">
            <b-skeleton></b-skeleton>
            <b-skeleton></b-skeleton>
          </p>
        </b-alert>
      </template>

      <AssessmentComponentCompletion class="shadow-sm" />
      <GradeEntrySchedule class="shadow-sm" />
    </b-skeleton-wrapper>

    <b-card class="shadow-sm" header-class="pl-2 card-header-inline" body-class="pt-0">
      <template v-slot:header>
        <BackButton
          class="mr-1"
          :redirect-to="backButtonRedirect"
        />
        <header class="pt-2">
          Nilai Mahasiswa
        </header>
      </template>

      <DetailMatakuliah />

      <b-button-toolbar
        class="mb-3"
      >
        <b-button-group
          class="mt-sm-2 mr-2"
        >
          <TitleIconButton
            @onClick="assessComponentForm"
            tooltip-text="Edit Komponen Nilai"
            caption="Komponen Nilai"
          >
            <template v-slot:icon>
              <component-icon></component-icon>
            </template>
          </TitleIconButton>
        </b-button-group>
        <b-button-group
          class="mt-sm-2 mr-2"
        >
          <b-dropdown
            class="btn-block"
            variant="outline-info"
            :disabled="activeSemesterState"
            v-if="assessComponentData.length > 0"
          >
            <template v-slot:button-content>
              <download-icon></download-icon>
              Template Nilai
            </template>
            <b-dropdown-item-button
              @click="downloadTemplate('all')"
            >
              Seluruh Komponen Nilai
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-for="assComp of assessComponentData" :key="assComp.komPenId"
              @click="downloadTemplate(assComp.komPenNama, assComp)"
            >
              Komponen Nilai ({{ assComp.komPenNama }})
            </b-dropdown-item-button>
          </b-dropdown>
        </b-button-group>

        <b-button-group
          class="mt-sm-2"
        >
          <b-dropdown
            variant="outline-primary"
            class="btn-block"
            :disabled="activeSemesterState"
          >
            <template v-slot:button-content>
              <upload-icon></upload-icon>
              Import Nilai
            </template>
            <b-dropdown-item-button
              @click="importUrlByTemplate('AllKomponen')"
            >
              Seluruh Komponen
            </b-dropdown-item-button>
            <b-dropdown-item-button
              @click="importUrlByTemplate('PerKomponen')"
            >
              Per Komponen
            </b-dropdown-item-button>
          </b-dropdown>
        </b-button-group>
      </b-button-toolbar>

      <c-table
        primary-key="klsId"
        :table-data="items"
        :fields="captions"
        :loading="loading"
      >
        <template v-slot:cell(index)="{ index }">
          {{ index + 1 }}
        </template>

        <template v-slot:cell(status)="{ item }">
          <c-button
            v-if="item.nilaiMutu === 'BL'"
            variant="outline-danger"
            size="sm"
          >
            <failed-icon></failed-icon>
            Belum Lengkap
          </c-button>
          <c-button
            v-else-if="item.nilaiMutu !== ''"
            variant="outline-success"
            size="sm"
          >
            <success-icon></success-icon>
            Lengkap
          </c-button>
          <c-button
            v-else
            variant="outline-success"
            size="sm"
          >
            <failed-icon></failed-icon>
            Tidak Lengkap
          </c-button>
        </template>

        <template v-slot:cell(action)="{ item }">
          <b-button-group
            vertical
            class="justify-content-center"
          >
            <TitleIconButton
              :disabled="activeSemesterState"
              @onClick="assessmentsForm(item)"
              tooltip-text="Edit Nilai Mahasiswa"
            >
              <template v-slot:icon>
                <edit-icon></edit-icon>
              </template>
            </TitleIconButton>
          </b-button-group>
        </template>
      </c-table>

      <b-modal
        id="AssessmentForm"
        centered
        hide-footer
        no-close-on-esc
        hide-header-close
        title="Nilai Mahasiswa"
        v-if="modalShownIfSelected"
      >
        <h4 class="title">
          [{{ modalTitleForm }}]
        </h4>
        <b-form @submit.prevent="onSubmit">
          <div
            v-for="(assComp, idx) in assessComponentData"
            :key="idx"
          >
            <b-form-group :label="assComp.komPenNama">
              <b-form-input
                min="0"
                max="100"
                type="number"
                step="0.01"
                v-model="selectedStudentAssessments[idx].nilai"
              />
            </b-form-group>
          </div>
          <b-button-group>
            <LoadingButton
              variant="primary"
              :disabled="buttonState"
              :loading="buttonState"
              size="md"
            >
              Simpan
            </LoadingButton>
            <c-button
              variant="outline-info"
              :disabled="buttonState"
              @click="$bvModal.hide('AssessmentForm')"
            >
              Tutup
            </c-button>
          </b-button-group>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import {
  ModalMessageMixin, AssessmentTemplateMixin, TableMixin,
  BackButton, TitleIconButton, LoadingButton, Button as CButton,
  ComponentIcon,
  DownloadIcon,
  UploadIcon,
  FailedIcon,
  SuccessIcon,
  EditIcon,
  Table as CTable
} from '@/components'
import {
  join, delay, mergeAssessment, activeSemesterButtonState,
  mapAssessComponent, equalSemester
} from '@/helpers'
// module
import DetailMatakuliah from '@/modules/matakuliah/Detail'
import GradeEntrySchedule from '@/modules/nilai/GradeEntrySchedule'
import AssessmentComponentCompletion from '@/modules/komponen/Completion'

export default {
  name: 'KelolaNilaiMahasiswa',
  mixins: [TableMixin, ModalMessageMixin, AssessmentTemplateMixin],
  components: {
    AssessmentComponentCompletion,
    DetailMatakuliah,
    GradeEntrySchedule,
    BackButton,
    LoadingButton,
    TitleIconButton,
    CTable,
    CButton,
    ComponentIcon,
    DownloadIcon,
    UploadIcon,
    FailedIcon,
    SuccessIcon,
    EditIcon
  },
  data () {
    return {
      activeSemesterButton: false,
      assessComponentData: [],
      assessments: [],
      selectedStudent: {},
      selectedStudentAssessments: [],
      buttonState: false,
      redirectTo: {
        name: 'dosen.mahasiswa-nilai',
        params: this.$route.params
      },
      backButtonRedirect: {
        name: 'dosen.kelola-nilai'
      }
    }
  },
  computed: {
    ...mapGetters({
      userDetail: 'users/getUserDetail',
      userProfile: 'users/getUserProfile',
      facultyId: 'faculty/getFacultyId',
      matakuliah: 'komponenPenilaian/getMatakuliah'
    }),
    ...mapState('assessment', {
      lectureCanEntry: 'lectureCanEntryAssessment',
      loadingGrantAccess: 'loadingGrantAccess'
    }),
    ...mapState('faculty', {
      activeSemester: 'activeSemesterId'
    }),
    ...mapState('komponenPenilaian', {
      currentSemester: 'semesterId',
      semesterNama: 'semesterNama',
      assessmentComponenExist: 'assessmentComponentExist',
      percentageReached: 'percentageReached',
      loading: 'loadingMahasiswa',
      loadingKomponenPenilaian: 'loadingKomponenPenilaian'
    }),
    captions () {
      const assessComponentCaptions = this.assessComponentData.map(
        (item) => ({
          key: 'nilai' + item.komPenId,
          label: item.komPenNama,
          sortable: false
        })
      )

      return [
        { key: 'index', label: '#', sortable: true },
        { key: 'mhsNiu', label: 'NIM', sortable: true },
        { key: 'mhsNama', label: 'Mahasiswa', sortable: true },
        { key: 'mhsAngkatan', label: 'Angkatan', sortable: true },
        ...assessComponentCaptions,
        { key: 'nilaiAngka', label: 'Nilai Angka', sortable: false },
        { key: 'nilaiMutu', label: 'Nilai Huruf', sortable: false },
        { key: 'status', label: 'Status Nilai', sortable: false },
        { key: 'action', label: 'Aksi', sortable: false }
      ]
    },
    modalTitleForm () {
      return join(', ', [this.selectedStudent.mhsNiu, this.selectedStudent.mhsNama])
    },
    modalShownIfSelected () {
      return this.assessmentComponenExist && this.selectedStudentAssessments.length > 0
    },
    activeSemesterState () {
      return activeSemesterButtonState(
        this.lectureCanEntry,
        this.activeSemesterButton,
        this.assessmentComponenExist
      )
    }
  },
  beforeMount () {
    this.init()
  },
  beforeDestroy () {
    this.resetCompletionCheck()
    this.flushGrantAccess()
  },
  methods: {
    ...mapActions({
      checkGrantAssessment: 'assessment/checkGrantEntryAssessment',
      flushGrantAccess: 'assessment/flushGrantAccess',
      validateAssessmentComponent: 'assessment/validateAssessmentComponent',
      submitAssessment: 'assessment/submitAssessment',
      getMatakuliah: 'komponenPenilaian/getInfoMatakuliah',
      getAllMahasiswaByKelasMatkul: 'komponenPenilaian/getAllMahasiswaByKelasMatkul'
    }),
    ...mapMutations('komponenPenilaian', {
      resetCompletionCheck: 'resetCompletionCheck'
    }),
    init () {
      this.activeSemesterButton = equalSemester(this.activeSemester, this.currentSemester)
      Promise.all([
        this.getMatakuliah(this.$route.params.kelasId),
        this.validateAssessmentComponent(this.$route.params.kelasId)
      ]).then(([_, assessComponent]) => {
        this.assessComponentData = assessComponent
        return this.tableDataProvider()
      }).catch(err => this.modalValidation(err))
    },
    dataProvider () {
      return this.getAllMahasiswaByKelasMatkul(
        this.$route.params.kelasId
      ).then(({ data }) => {
        const dataTable = mapAssessComponent(data, this.assessComponentData)
        return { data: dataTable }
      })
    },
    modalValidation (errMsg) {
      this.modalMessage(errMsg.message, {
        redirectTo: this.redirectTo
      })
    },
    assessmentsForm (assessments) {
      delay(120).then(() => {
        this.selectedStudent = assessments
        this.selectedStudentAssessments = mergeAssessment(
          this.assessComponentData, assessments.nilai, assessments.krsdtId
        )
      }).then(() => {
        this.$bvModal.show('AssessmentForm')
      })
    },
    assessComponentForm () {
      this.$router.push({
        name: 'dosen.komponen-nilai',
        params: this.$route.params
      })
    },
    onSubmit () {
      this.buttonState = true
      this.submitAssessment({
        kelasId: this.$route.params.kelasId,
        assessment: this.selectedStudentAssessments
      }).then(() => {
        this.$bvModal.hide('AssessmentForm')
      }).then(() => {
        this.buttonState = false
        return this.tableDataProvider()
      })
    }
  }
}
</script>

<style scoped>
.card-header-inline {
  display: inline-flex;
}
</style>
